<template>
	<div class="third-step">
		<div class="third-step__header">
			<h2 class="auth-form__title">Давайте знакомиться</h2>
			<h3 class="auth-form__subtitle">
				Укажите ваше имя и адрес электронной почты, на него будем присылать чеки
			</h3>
		</div>
		<ValidationObserver
			class="third-step__form"
			tag="form"
			ref="thirdStepForm"
			v-slot="{ invalid }"
			@submit.prevent="handleSubmitForm"
		>
			<UiValidatedInput
				v-model="form.firstName"
				id="auth-name"
				label="Ваше имя"
				name="firstName"
			/>
			<UiValidatedInput
				rules="email"
				v-model="form.email"
				id="auth-email"
				label="Электронная почта"
				name="email"
			/>
			<UiValidatedInput
				v-model="form.promocode"
				id="auth-promocode"
				label="Промокод"
				:error="promocodeError"
				name="promocode"
				:disabled="!!user?.UF_AFFILIATE"
				@clearError="promocodeError = ''"
			/>
			<UiButton
				:disabled="invalid || disabled"
				:loading="loading"
			>
				Продолжить
			</UiButton>
			<UiButton
				@click.prevent="setAuthFormPopup(false); window.location.reload();"
				variant="text"
				size="small"
			>
				Пропустить
			</UiButton>
		</ValidationObserver>
	</div>
</template>
<script>
import {UiValidatedInput, UiButton} from "ui-kit";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {notification} from "@/utils";

export default {
	name: "ThirdStep",
	components: {UiButton, UiValidatedInput},
	data()
	{
		return {form: {firstName: '', email: '', promocode: ''}, promocodeError: '', loading: false};
	},
	computed: {
		...mapGetters({
			user: "getUser",
		}),
		disabled()
		{
			return this.form.firstName === '' && this.form.email === '' && this.form.promocode === '';
		}
	},
	methods: {
		...mapMutations({
			setAuthFormPopup: "setAuthFormPopup",
			setUser: "setUser",
		}),
		...mapActions({
			updateProfileInfo: "personal/updateProfileInfo",
		}),
		async handleSubmitForm()
		{
			const isValid = await this.$refs.thirdStepForm.validate();
			if (!isValid) return;

			this.loading = true;

			try
			{
				const {data} = await this.updateProfileInfo(this.form);

				if (!data.success && data.error['promocode']) return this.promocodeError = data.error['promocode'];

				if (!data.success && data.error.showMsg) return notification({
					title: data.error.showMsg,
					type: "error"
				});

				if (!data.success) return notification({title: "Неизвестная ошибка", type: "error"});

				this.setUser(data.user);

				this.setAuthFormPopup(false);

				const userAgent = window.navigator.userAgent.match(/mobileApp/i);
				if (userAgent) return window.location.href = '/application/personal/';

				window.location.reload();
			} catch (e)
			{
				throw new Error(e)
			} finally
			{
				this.loading = false;
			}
		}
	},
	mounted()
	{
		if (this.user) this.form.promocode = this.user?.UF_AFFILIATE || '';
	}
}
</script>
<style lang="scss">
.third-step
{
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.third-step__header
{
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.third-step__form
{
	display: flex;
	flex-direction: column;
	gap: 30px;
}
</style>